import React from "react";
import "./AboutUs.css";
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from "react-router-dom";
import { BsPencilFill } from 'react-icons/bs';
import { BiColor } from "react-icons/bi";



function AboutUs() {
    return (
        <>
            <section className="titlebar columns ">
                <div className="row1">
                    <div className="text-center mt-4 mb-3 textabout">
                        <h1 id="page-title" className="title  fnt1" > IMPRO LECITIHIN LLP</h1>
                        <div className=" text-center  ">
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid">
                <div>

                    <div className=" container-fluid">
                        <div className="row ">
                            <div className="col-sm-6 mt-5 ">
                                <div className="text-center mt-5">
                                    <div className="text-center">
                                        <h3 className="head-style inf fnt" >History</h3>
                                    </div>

                                </div>
                                <div className="mt-5">
                                    <p className="fnt textalign">
                                    Established in 1996, our journey began fueled by an unwavering passion for unlocking the boundless 
                                    potential of lecithin. Our visionary founders swiftly discerned the remarkable versatility and myriad
                                     benefits that lecithin could offer across diverse industries. Their resolute mission was to harness
                                      this extraordinary potential to pioneer innovative solutions. Through the passage of time, we have 
                                      evolved from a humble startup into a trailblazing industry leader, continually challenging the limits
                                       of what lecithin can accomplish.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6 mt-5">
                                <img src="./images/improimg/improimg (19).jpg" alt="Nature" className="responsive w-100" />
                            </div>
                        </div>
                    </div>
                    <div className=" container-fluid">
                        <div className="row ">
                            {/* <div className="text-center mt-5">
                            <div className="text-center">
                                <h3 className="head-style inf" style={{ fontFamily: "'uni_neuebook'" }}>Company's Profile</h3>
                            </div>
                            
                        </div> */}
                            <div className="w3-row w3-container mt-5 ">
                                <div className="w3-light-grey">
                                <div className="w3-col  m6 w3-light-grey w3-container w3-padding-16" style={{height:"120px"}}>
                                    <h3 className="fnt">Our Vision</h3>
                                    <p className="fnt">TO BE THE MOST AUTHENTIC AND TRUSTED SUPPLIER OF WORLD MARKET FOR NON GMO LECITHIN.</p>
                                </div>
                                </div>

                                <div className="w3-col  m6 w3-dark-grey w3-container w3-padding-16">
                                    <h3 className="fnt" >Our Mission</h3>
                                    <p className="fnt">TO OFFER HIGHEST QUALITY NON GMO LECITHIN AND WORK TOWARDS ENRICHING CUSTOMERS EXPERIENCES.</p>
                                </div>

                                <div className="w3-col  m6 w3-dark-grey w3-container w3-padding-16">
                                    <h3 className="fnt">FOOD SAFETY POLICY</h3>
                                    <p className="fnt">We are committed to providing our Food/Feed products as per statutory, regulatory and mutually agreed
                                        Food/Feed safety requirements of the customer by implementing and maintaining Food/Feed safety management system.</p>
                                </div>

                                <div className="w3-col  m6 w3-light-grey w3-container w3-padding-16">
                                    <h3 className="fnt">QUALITY POLICY</h3>
                                    <p className="fnt">We at IMPRO LECITHIN LLP , are committed to manufacture and continuously improve the quality of our products to
                                        maintain customer satisfaction by implementing Good Manufacturing Practices and a Quality Management System.
                                        </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=" container-fluid">

                        <div className="text-center mt-5">
                            <div className="text-center">
                                <h3 className="head-style inf fnt" >Director's Desk</h3>
                            </div>

                        </div>
                        <div className="mt-5 cotainer">
                        <div className="row ">
                        <div className="col-sm-6 mt-5">
                                <img src="./images/improimg/improimg33.jpg" alt="Nature" className="responsive w-100" />
                            </div>
                            <div className="col-sm-6 mt-5 ">
                            <p className="fnt  textalg textalign" >
                            I would like to take a moment to articulate my vision for our organization and our steadfast dedication to 
                            offering exceptional Non-GMO Lecithin products to the global community. In today's health-conscious and 
                            environmentally sensitive world, consumers are actively seeking natural and sustainable options for their 
                            dietary preferences. Non-GMO Lecithin has emerged as a significant player in this movement, and it is our 
                            purpose to lead this charge. Our commitment to maintaining the highest standards of quality is resolute. We
                             will meticulously source and manufacture Non-GMO Lecithin of unparalleled quality, ensuring that each product 
                             not only meets but surpasses international benchmarks. Quality control and assurance will be seamlessly 
                             integrated into every facet of our production process. We acknowledge our responsibility towards the
                              environment. We will explore sustainable sourcing methodologies, minimize waste, and adopt eco-friendly 
                              technologies wherever feasible. Our objective is to set a benchmark for sustainability within our industry. 
                              We will continually invest in research and development to stay ahead of the curve. Our team will explore 
                              inventive approaches to enhance the properties and applications of Non-GMO Lecithin, ensuring our position 
                              as pioneers in the field. We aspire to make our Non-GMO Lecithin accessible to consumers on a global scale.
                               This entails expanding our distribution networks, forging strategic alliances, and adhering rigorously to
                                international regulatory norms. We are committed to being a reliable fount of knowledge regarding Non-GMO
                                 Lecithin. Transparency in our operations and elucidating the advantages of our products will constitute
                                  the essence of our interactions with our customers. Our customers will always be our foremost priority.
                                   Their needs and insights will steer our decisions and fuel our ongoing quest for enhancement.       
                                   
                                
                            </p>
                            </div>
                            </div>
                            <div className="text-end fnt"><BsPencilFill  style={{color:"orange"}}/> Director......Mr. Kamlesh Mangharamani. </div> 
                        </div>
                    </div>

                    {/* </div>
                    </div> */}

                    {/* <div className="container mt-5">
                    <div className="text-center">
                        <div className="text-center">
                            <h3 className="head-style inf">Our Team</h3>
                        </div>
                        <div>
                            <p>Board Of Management</p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <div className="thumbnail">
                                <a href="">
                                    <div className="containerteam">
                                        <img className="teamimg" src="./images/improimg/improimg (10).jpg" alt="Lights" style={{ width: "100%" }} />
                                        <div className="overlayteam">My Name is John</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="thumbnail">
                                <a href="">
                                    <div className="containerteam">
                                        <img className="teamimg" src="./images/improimg/improimg (23).jpg" alt="Lights" style={{ width: "100%" }} />
                                        <div className="overlayteam">My Name is John</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="thumbnail">
                                <a href="">
                                    <div className="containerteam">
                                        <img className="teamimg" src="./images/improimg/improimg (12).jpg" alt="Lights" style={{ width: "100%" }} />
                                        <div className="overlayteam">My Name is John</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> */}
                    <div className="w3-container" style={{ padding: "128px 16px" }} id="team">
                        <div className="text-center">
                            <div className="text-center">
                                <h3 className="head-style inf" >Our Team</h3>
                            </div>
                            {/* <div>
                            <p>Board Of Management</p>
                        </div> */}
                        </div>
                        {/* <div className="w3-row-padding w3-grayscale" style={{ marginTop: "64px" }}>
                            <div className="w3-col l3 m6 w3-margin-bottom">
                                <div className="w3-card">
                                    <img src="./images/improimg/improimg (12).jpg" alt="John" style={{ width: "100%" }} />
                                    <div className="w3-container">
                                        <h5>Mr. KAMLESH MANGHARAMANI</h5>
                                        <p className="w3-opacity">Our Director</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w3-col l3 m6 w3-margin-bottom">
                                <div className="w3-card">
                                    <img src="./images/improimg/improimg (12).jpg" alt="Jane" style={{ width: "100%" }} />
                                    <div className="w3-container">
                                        <h5>Anja Doe</h5>
                                        <p className="w3-opacity">Our Quality Assurance Manager</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs;