import React from "react";
import "./Certificate.css";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from 'react-icons/bs';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Certificate() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <section className="titlebar columns ">
                <div className="row1">
                    <div className="text-center mt-4 mb-3 textabout">
                        <h1 id="page-title" className="title  fnt">Certificate</h1>
                        <div className=" text-center  ">
                        </div>
                    </div>
                </div>
            </section>
            <div className="container me-5">

                <div className="row ">
                    <div className="col-md-3 ">


                        <img src="./images/certificates/fssc.png" alt="Nature" style={{ width: "68%" }} className="mt-2" />


                    </div>
                    <div className="col-md-3">


                        <img src="./images/certificates/usfda.png" alt="Fjords" style={{ width: "68%" }} className="mt-5" />


                    </div>
                    <div className="col-md-3">


                        <img src="./images/certificates/klbdkashar.jpg" alt="Fjords" style={{ width: "48%" }} />


                    </div>

                    <div className="col-md-3">


                        <img src="./images/certificates/fssailogo.png" alt="Fjords" style={{ width: "68%" }} className="mt-3"/>


                    </div>
                    <div className="col-md-4 mb-3 mt-5">


                        <img src="./images/certificates/foodchainnongmo.jpg" alt="Lights" style={{ width: "43%", marginLeft:"94px" }} />



                    </div>
                    <div className="col-md-4 mt-5">


                        <img src="./images/certificates/halal.png" alt="Fjords" style={{ width: "43%" , marginLeft:"60px" }} className="mt-2" />


                    </div>
                    <div className="col-md-4 mt-5 ">


                        <img src="./images/certificates/gmp.jpg" alt="Fjords" style={{ width: "48%" }} className="mt-2" />


                    </div>


                </div>

            </div>

        </>
    );
}

export default Certificate;