import React from "react";
import "./ContactUs.css";
import { ImLocation2 } from 'react-icons/im';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { Link } from "react-router-dom";
import { BsArrowRightShort } from 'react-icons/bs';
import emailjs from '@emailjs/browser';
import { useState } from "react";

// function ContactUs(){
//     return(
//         <>

//         </>
//     );
// }
// export default ContactUs;

const ContactUs = () => {
  // const [formStatus, setFormStatus] = React.useState('Send')  
  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   setFormStatus('Submitting...')
  //   const { name, email, message } = e.target.elements
  //   let conFom = {
  //     name: name.value,
  //     email: email.value,
  //     message: message.value,
  //   }
  //   console.log(conFom)
  // }
                           
  let icoStyle = {
    color: "blue",
    fontSize: "1.5rem",
    height: "-webkit-fill-available",
  };

  function sendEmail(e) {
    e.preventDefault();


    emailjs.sendForm('service_gb3irbe', 'template_d5npkid', e.target, 'uY_Uk8VUnDPmylK6C').then(res => {
      console.log(res);
    }).catch(err => console.log(err));

  }


  const [showMessage, setShowMessage] = useState(false);

  const showMessageForDuration = () => {
    setShowMessage(true);

    // Set a timer to hide the message after 3 seconds (adjust as needed)
    setTimeout(() => {
      setShowMessage(false);
    }, 3000); // 3000 milliseconds (3 seconds)
  };


  return (
    <>
      <div>
        <section className="titlebar columns ">
          <div className="row1">
            <div className="text-center mt-4 mb-3">
              <h1 id="page-title" className="title fnt">Contact Us</h1>
              <div className=" text-center  ">
              
              </div>
            </div>
          </div>
        </section>
       
        <div className="container mb-4">
          <div></div>
          <div className="row row-cont">
            <div className="col-sm-7 ">
              <div className="container mt-5 containermt">
                <h2 className="mb-3 fnt">Send Us A Message</h2>
                {showMessage && <div className="message text-light" style={{backgroundColor:"orange"}}> Thank You for Contacting us!</div>}
                <form onSubmit={sendEmail} className="" >
                  <div>
                    <div className="mb-3 ">

                      <label className="form-label fnt" htmlFor="name" >
                        Name
                      </label>
                      <input className="form-control" type="text" id="name" required name="name" />

                    </div>
                    <div className="mb-3 ">

                      <label className="form-label fnt" htmlFor="name">
                        Company Name
                      </label>
                      <input className="form-control" type="text" id="company" required name="company" />

                    </div>
                    <div className="mb-3 ">

                      <label className="form-label fnt" htmlFor="name">
                        Contact Number
                      </label>
                      <input className="form-control" type="text" id="contact" required name="contact" />

                    </div>
                    <div className="mb-3">
                      <label className="form-label fnt" htmlFor="email">
                        Email
                      </label>
                      <input className="form-control" type="email" id="email" required name="email" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label fnt" htmlFor="message" >
                        Message
                      </label>
                      <textarea className="form-control" id="message" required name="message" />
                    </div>
                    <button className="btn btn-color" type="submit" value="Send Email" onClick={showMessageForDuration}  >
                     
                      {/* {formStatus} */}
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-sm-5 contactinf">
              <div className=" mt-5">
                <h2 className="mb-3 text-white mt-5 fnt text-center">Contact Information</h2>
                <div className="text-center text-white mt-5">
                  <p className="fnt">
                    Let's Start a Conversation
                  </p>
                </div>
                <div className="text-center text-white">
                  <ImLocation2 style={icoStyle} />
                  <p className="fnt">
                    SURVEY No. 157/2 VILLAGE SHAHNA, <br />POST DHARAMPURI, DIST. INDORE - 453771 INDIA</p> <br />
                  <BiSolidPhoneCall style={icoStyle} /> <a href="tel:+91 9754 799 799" style={{ textDecoration: "none" }}> <p className="fnt text-light">+91 9754 799 799</p> </a> <br />
                  <MdEmail style={icoStyle} />
                  <Link to="mailto:info@improlecithin.com" style={{ textDecoration: "none" }}>
                    <p className="fnt text-light" >info@improlecithin.com</p>
                  </Link>
                  <Link to="mailto:improlecithinllp@gmail.com" style={{ textDecoration: "none" }}>
                    <p className="fnt text-light" style={{ marginTop: "-12px" }}>improlecithinllp@gmail.com</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      {/* <form method="post">
        <input type="button" value="Send Email"
          onclick={sendEmail()} />
      </form> */}
    </>
  )
}

// function sendEmail() {
//   Email.send({
//     Host: "smtp.gmail.com",
//     Username: "sender@email_address.com",
//     Password: "Enter your password",
//     To: 'developerji71@gmail.com',
//     // To: 'receiver@email_address.com',
//     From: "sender@email_address.com",
//     Subject: "Sending Email using javascript",
//     Body: "Well that was easy!!",
//   })
//     .then(function (message) {
//       alert("mail sent successfully")
//     });
// }
export default ContactUs;