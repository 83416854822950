import React from "react";
import "./Infrastructure.css";
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function Infrastructure() {
    return (
        <>
            <div >
                <section className="titlebar columns ">
                    <div className="row1">
                        <div className="text-center mt-4 mb-3">
                            <h1 id="page-title" className="title fnt ">Infrastructure</h1>
                            <div className=" text-center  ">

                            </div>
                        </div>
                    </div>
                </section>


                <div className="container marinfra">
                    <div className="text-center ">
                        <h3 className="head-style inf fnt " >Our Factory</h3>
                    </div>

                    <div className="row mt-3">

                        <div className="col-md-4 mb-3">
                            <div className="thumbnail ">

                                <img src="./images/improimg/improimg (1).jpg" alt="Lights" style={{ width: "100%" }} className="mt-2"

                                    data-bs-toggle="modal" data-bs-target="#imageExample" />



                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="thumbnail">

                                <img src="./images/improimg/impronew4.jpg" alt="Nature" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExample2" />



                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="thumbnail">

                                <img src="./images/improimg/improimg (3).jpg" alt="Fjords" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExample3" />


                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="thumbnail">

                                <img src="./images/improimg/improimg (22).jpg" alt="Fjords" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExample10" />


                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="thumbnail">

                                <img src="../images/improimg/improimg (5).jpg" alt="Fjords" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExample11" />


                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="thumbnail">

                                <img src="../images/improimg/improimg (6).jpg" alt="Fjords" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExample12" />


                            </div>
                        </div>

                        <div className="col-md-4 mt-3 ">
                            <div className="thumbnail">

                                <img src="../images/improimg/lab1.png" alt="" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExample1234" />


                            </div>
                        </div>

                        <div className="col-md-4 mt-3">
                            <div className="thumbnail">

                                <img src="../images/improimg/infra12.jpg" alt="" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExampleinfra" />


                            </div>
                        </div>

                        <div className="col-md-4 mt-3">
                            <div className="thumbnail">

                                <img src="../images/improimg/lab2.png" alt="" style={{ width: "100%" }} className="mt-2"
                                    data-bs-toggle="modal" data-bs-target="#imageExample12345" />


                            </div>
                        </div>

                       







                    </div>
                </div>


                <div className="modal fade" id="imageExample" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>

                <div className="modal fade" id="imageExample2" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExampl3" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExample10" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExample11" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExample12" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>

                <div className="modal fade" id="imageExample1234" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>

             


                <div className="modal fade" id="imageExampleinfra" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>

                <div className="modal fade" id="imageExample12345" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/lab2.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/lab1.png" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/infra12.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (6).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (1).jpg" className="w100h50  w-100 d-block"></img>
                                <img src="./images/improimg/impronew4.jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (3).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (22).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (5).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>


                <div className="text-center mt-5 container">
                    <h3 className="head-style inf fnt" >Our Laboratory</h3>
                    <div className="row">

                        <div className="col-md-4 mb-3 mt-3">
                            <div className="thumbnail ">
                                <img src="./images/improimg/improimg (10).jpg" alt="Lights" style={{ width: "100%" }}
                                    data-bs-toggle="modal" data-bs-target="#imageExamplelab1" />
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mt-3">
                            <div className="thumbnail ">
                                <img src="./images/improimg/improimg (11).jpg" alt="Lights" style={{ width: "100%" }}
                                    data-bs-toggle="modal" data-bs-target="#imageExamplelab2" />
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mt-3">
                            <div className="thumbnail ">
                                <img src="./images/improimg/improimg (12).jpg" alt="Lights" style={{ width: "100%" }}
                                    data-bs-toggle="modal" data-bs-target="#imageExamplelab3" />
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mt-3">
                            <div className="thumbnail ">
                                <img src="./images/improimg/improimg (13).jpg" alt="Lights" style={{ width: "100%" }}
                                    data-bs-toggle="modal" data-bs-target="#imageExamplelab4" />

                            </div>

                        </div>
                        <div className="col-md-4 mb-3 mt-3">
                            <div className="thumbnail ">
                                <img src="./images/improimg/improimg (14).jpg" alt="Lights" style={{ width: "100%" }}
                                    data-bs-toggle="modal" data-bs-target="#imageExamplelab5" />

                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mt-3">
                            <div className="thumbnail ">
                                <img src="./images/improimg/improimg (17).jpg" alt="Lights" style={{ width: "100%" }}
                                    data-bs-toggle="modal" data-bs-target="#imageExamplelab6" />

                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="imageExamplelab1" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >

                                <img src="./images/improimg/improimg (10).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (11).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (12).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (13).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (14).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (17).jpg" className="w100h50  d-block w-100"></img>


                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExamplelab2" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (11).jpg" className="w100h50  d-block w-100"></img>

                                <img src="./images/improimg/improimg (10).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (12).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (13).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (14).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (17).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExamplelab3" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (12).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (10).jpg" className="w100h50  d-block w-100"></img>

                                <img src="./images/improimg/improimg (11).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (13).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (14).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (17).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExamplelab4" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (13).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (10).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (11).jpg" className="w100h50  d-block w-100"></img>

                                <img src="./images/improimg/improimg (12).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (14).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (17).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExamplelab5" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (14).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (10).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (11).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (12).jpg" className="w100h50  d-block w-100"></img>

                                <img src="./images/improimg/improimg (13).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (17).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
                <div className="modal fade" id="imageExamplelab6" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog" style={{ pointerEvents: "auto" }}>
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" >

                            </button>
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                showStatus={false}
                                dots={true}
                                showIndicators={false}
                            >
                                <img src="./images/improimg/improimg (17).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (10).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (11).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (12).jpg" className="w100h50  d-block w-100"></img>
                                <img src="./images/improimg/improimg (13).jpg" className="w100h50  d-block w-100"></img>

                                <img src="./images/improimg/improimg (14).jpg" className="w100h50  d-block w-100"></img>

                            </Carousel>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Infrastructure;